import React, { FC } from 'react'
import { scroller } from 'react-scroll'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Box,
  Button,
  Container,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import HomeData from 'content/home.json'
import Icons, { IconsName } from 'components/Icons'
import { sectionPaddingY } from 'styles/global'

const HomeFeatures: FC = () => {
  const { t } = useTranslation()
  const { features } = HomeData

  return (
    <Container id="home-features">
      <Box textAlign="center" padding={sectionPaddingY}>
        <Heading maxW="1200px" margin="0 auto">
          {t('home.features.title')}
        </Heading>
        <Tabs variant="solid-rounded" colorScheme="black" marginTop="8">
          <TabList bg={{ base: 'unset', md: 'blue.100' }}>
            {features.tabs.map((tab, idx) => (
              <Tab key={idx} bg={{ base: 'blue.100', md: 'unset' }}>
                {t(tab)}
              </Tab>
            ))}
          </TabList>

          <TabPanels mt={['3rem', '4.25rem']}>
            <TabPanel>
              <SimpleGrid columns={[1, 2, 3]} spacing={8} spacingY={[10, 16]}>
                {features.business.map((icon, idx) => (
                  <Box key={idx}>
                    <IconButton
                      as="span"
                      aria-label={icon}
                      icon={<Icons name={icon as IconsName} />}
                      bg="black"
                      color="white"
                      variant="icon"
                    />
                    <Text mt={['4', '5']} size="xl" fontWeight="500">
                      {t(`home.features.business.${idx}.title`)}
                    </Text>
                    <Text mt={['1', '2']} color="gray.400">
                      {t(`home.features.business.${idx}.text`)}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={[1, 2, 3]} spacing={8} spacingY={[10, 16]}>
                {features.customer.map((icon, idx) => (
                  <Box key={idx}>
                    <IconButton
                      as="span"
                      aria-label={icon}
                      icon={<Icons name={icon as IconsName} />}
                      bg="black"
                      color="white"
                      variant="icon"
                    />
                    <Text mt={['4', '5']} size="xl" fontWeight="500">
                      {t(`home.features.customer.${idx}.title`)}
                    </Text>
                    <Text mt={['1', '2']} color="gray.400">
                      {t(`home.features.customer.${idx}.text`)}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Button
          variant="black"
          mt={['2.5rem', '6.5rem']}
          size="lg"
          w={['100%', 'unset']}
          onClick={() =>
            scroller.scrollTo('main-form', { smooth: 'easeInOutQuint' })
          }
        >
          {t('home.features.button')}
        </Button>
      </Box>
    </Container>
  )
}
export default HomeFeatures
