import React, { FC, useEffect, useRef, useState } from 'react'
import { scroller } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Typed from 'typed.js'
import {
  Container,
  Box,
  Heading,
  Button,
  Text,
  Flex,
  Tooltip,
} from '@chakra-ui/react'
import Icons from 'components/Icons'
import { StyledHero } from 'styles/global'

const HomeHero: FC = () => {
  const { t } = useTranslation()
  const el: any = useRef(null)
  const typed: any = useRef(null)
  const [multiAssetsTooltip, setAssetsTooltip] = useState(false)
  const [crossMarketTooltip, setMarketTooltip] = useState(false)
  const heroImg = useStaticQuery(graphql`
    query {
      desktopImage: file(relativePath: { eq: "home/hero.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            height: 640
          )
        }
      }
      mobileImage: file(relativePath: { eq: "home/hero-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
        }
      }
    }
  `)
  const images = withArtDirection(
    heroImg.desktopImage.childImageSharp.gatsbyImageData,
    [
      {
        media: '(max-width: 767px)',
        image: heroImg.mobileImage.childImageSharp.gatsbyImageData,
      },
    ]
  )

  useEffect(() => {
    const options = {
      strings: [
        t('home.hero.rentalOperators'),
        t('home.hero.mobilityAssets'),
        t('home.hero.corporate'),
        t('home.hero.publicServices'),
      ],
      typeSpeed: 70,
      backSpeed: 20,
      loop: true,
      cursorChar: '_',
    }
    typed.current = new Typed(el.current, options)

    return () => {
      if (typed.current) typed.current.destroy()
    }
  }, [])

  return (
    <StyledHero
      id="home-hero"
      alignItems={{ base: 'unset', md: 'center' }}
      pt={{ base: '4.25rem', lg: '5rem' }}
    >
      <Container mt={{ base: '4rem', lg: 0 }}>
        <Box
          maxW={{ xl: '50%', lg: '80%', md: '100%' }}
          position="relative"
          zIndex="1"
          color={{ base: 'white', md: 'inherit' }}
        >
          <Heading as="h1" size="xl" fontWeight="700">
            {t('home.hero.title')}
          </Heading>
          <Text
            size="xxl"
            marginTop={['4', '6']}
            fontWeight="500"
            whiteSpace={['unset', 'unset', 'unset', 'nowrap']}
          >
            <Box as="span" display={{ base: 'block', md: 'initial' }}>
              {t('home.hero.subtitle')}
            </Box>
            <Box as="span" whiteSpace="pre" ref={el} />
          </Text>
          <Text size="lg" marginTop={['4', '8']} marginBottom={['8', '12']}>
            {t('home.hero.text.0')}
            <Tooltip
              label={t('home.hero.text.multiAssets.label')}
              isOpen={multiAssetsTooltip}
            >
              <span
                onClick={() => setAssetsTooltip(true)}
                onMouseEnter={() => setAssetsTooltip(true)}
                onMouseLeave={() => setAssetsTooltip(false)}
                style={{ fontWeight: 500, textDecoration: 'underline' }}
              >
                {t('home.hero.text.multiAssets.text')}
              </span>
            </Tooltip>
            {t('home.hero.text.1')}
            <Tooltip
              label={t('home.hero.text.crossMarket.label')}
              isOpen={crossMarketTooltip}
            >
              <span
                onClick={() => setMarketTooltip(true)}
                onMouseEnter={() => setMarketTooltip(true)}
                onMouseLeave={() => setMarketTooltip(false)}
                style={{ fontWeight: 500, textDecoration: 'underline' }}
              >
                {t('home.hero.text.crossMarket.text')}
              </span>
            </Tooltip>
            {t('home.hero.text.2')}
          </Text>
          <Flex flexDir={['column', 'row']}>
            <Button
              size="lg"
              variant="light"
              mr={[0, 3]}
              mb={[3, 0]}
              leftIcon={<Icons name="arrow-right-circle" />}
              iconSpacing="0.75rem"
              onClick={() =>
                scroller.scrollTo('home-features', { smooth: 'easeInOutQuint' })
              }
            >
              {t('home.hero.buttonSoftware')}
            </Button>
            <Button
              size="lg"
              variant="primary"
              onClick={() =>
                scroller.scrollTo('main-form', { smooth: 'easeInOutQuint' })
              }
            >
              {t('home.hero.buttonTrial')}
            </Button>
          </Flex>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            w: '100%',
            h: '100%',
            top: 0,
            left: 0,
            zIndex: -1,
            bg: 'black.600',
          }}
        >
          <GatsbyImage
            image={images}
            style={{ height: '100%', width: '100%' }}
            alt="hero image"
          />
        </Box>
        <Box
          sx={{
            bg: 'black.600',
            position: 'absolute',
            top: 0,
            left: 0,
            w: 'full',
            h: 'full',
            opacity: '.4',
            display: { base: 'block', md: 'none' },
          }}
        />
      </Container>
    </StyledHero>
  )
}
export default HomeHero
