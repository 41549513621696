import React, { FC } from 'react'
import { scroller } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from '@chakra-ui/react'
import HomeData from 'content/home.json'
import { useSt } from 'state/global/context'
import { sectionPaddingY } from 'styles/global'
import { getImage } from 'helpers/getImage'

const HomeBenefits: FC = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useSt()
  const { benefits } = HomeData
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home/benefits" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 64)
          }
          name
        }
      }
    }
  `)

  return (
    <Box bg="blue.100" color="black.400" id="home-benefits">
      <Container>
        <Box textAlign="center" p={sectionPaddingY}>
          <Heading maxW="1200px" margin="0 auto">
            {t('home.benefits.title')}
          </Heading>
          <Text maxW="1200px" margin="1.25rem auto" size="xl" color="gray.400">
            {t('home.benefits.subtitle')}
          </Text>
          <Tabs
            variant="solid-rounded"
            index={state.activeBenefitsTab}
            onChange={idx =>
              dispatch({ type: 'updateBenefitsTab', index: idx })
            }
          >
            <TabList
              bg={{ base: 'unset', md: 'blue.100' }}
              mixBlendMode="multiply"
              flexDir="row"
              justifyContent={{ base: 'center', lg: 'flex-start' }}
              flexWrap={{ base: 'wrap', lg: 'unset' }}
            >
              {benefits.tabs.map((tab, idx) => (
                <Tab
                  key={idx}
                  color="blue.700"
                  bg={{ base: 'blue.100', md: 'unset' }}
                  sx={{
                    '&:last-child': { mb: { base: '1rem', md: 0 } },
                  }}
                  mr={{ base: '1rem', md: 0 }}
                  _selected={{
                    color: 'beige',
                    bg: 'blue.600',
                  }}
                >
                  {t(tab)}
                </Tab>
              ))}
            </TabList>

            <TabPanels mt={['3.5rem', '5.5rem']}>
              {benefits.list.map(item => (
                <TabPanel key={item.id}>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, xl: 4 }}
                    spacing={{ base: '2rem', md: '3rem', xl: '2rem' }}
                  >
                    {item.images.map((img, idx) => (
                      <Box
                        key={idx}
                        bg="gray.50"
                        borderRadius="1rem"
                        padding="3.5rem 1.5rem 2rem"
                        position="relative"
                        sx={{
                          '&:not(:last-child)': {
                            mb: { base: '2.5rem', md: 0, xl: 0 },
                          },
                          mb: { base: '2.5rem', md: 0, xl: 0 },
                        }}
                      >
                        <Box
                          position="absolute"
                          top="-2rem"
                          left="50%"
                          transform="translateX(-50%)"
                        >
                          <GatsbyImage
                            alt={img}
                            image={getImage(images.allFile.nodes, img)}
                          />
                        </Box>
                        <Text size="xl" fontWeight="500" color="black.400">
                          {t(`${item.translation}.${idx}.title`)}
                        </Text>
                        <Text color="gray.400" mt="2">
                          {t(`${item.translation}.${idx}.text`)}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                  <Button
                    size="lg"
                    mt={['10', '16']}
                    variant="secondary"
                    w={['100%', 'unset']}
                    onClick={() =>
                      scroller.scrollTo('main-form', {
                        smooth: 'easeInOutQuint',
                      })
                    }
                  >
                    {t('home.benefits.button')}
                  </Button>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Container>
    </Box>
  )
}
export default HomeBenefits
