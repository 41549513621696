import React, { FC } from 'react'
import { scroller } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
import { sectionPaddingY } from 'styles/global'
import HomeData from 'content/home.json'
import Icons, { IconsName } from 'components/Icons'
import { getImage } from 'helpers/getImage'
import {
  Box,
  Container,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  List,
  ListItem,
  Button,
} from '@chakra-ui/react'

const HomeAssets: FC = () => {
  const { t } = useTranslation()
  const { assets } = HomeData
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home/assets" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              quality: 100
            )
          }
          name
        }
      }
    }
  `)

  return (
    <Box textAlign="center" p={sectionPaddingY} bg="black.600" color="white">
      <Container>
        <Heading maxW="1200px" margin="0 auto">
          {t('home.assets.title')}
        </Heading>
      </Container>
      <Tabs variant="solid-rounded" colorScheme="yellow" marginTop="8">
        <TabList bg={{ base: 'unset', md: 'yellow.200' }}>
          {assets.tabs.map((tab, idx) => (
            <Tab
              key={idx}
              bg={{ base: 'yellow.200', md: 'unset' }}
              _selected={{
                color: 'black',
                bg: 'primary',
              }}
            >
              {t(tab)}
            </Tab>
          ))}
        </TabList>

        <TabPanels mt={['3rem', '4rem']}>
          <TabPanel>
            <Container p={{ base: 0, md: 0 }}>
              <SimpleGrid
                gridTemplateColumns={{
                  base: '1fr',
                  lg: 'calc(50% - 6rem) 1fr',
                }}
                spacing={['2.5rem', '6rem']}
                alignItems="center"
              >
                <Box textAlign="left" p={{ base: '0 1rem' }}>
                  <Heading mb="8" size="sm" as="h3">
                    {t('home.assets.multimarket.title')}
                  </Heading>
                  <List spacing={['4', '7']}>
                    {assets.marketList.map((text, idx) => (
                      <ListItem display="flex" key={idx}>
                        <Box w="1.75rem" h="1.75rem">
                          <Icons name="check" />
                        </Box>
                        <Text key={idx} marginLeft="3" size="lg">
                          {t(text)}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, xl: 4 }}
                    spacing="6"
                    mt={['3rem', '4rem']}
                  >
                    {assets.multimarket.map((icon, idx) => (
                      <Box key={idx}>
                        <IconButton
                          as="span"
                          aria-label={icon}
                          icon={<Icons name={icon as IconsName} />}
                          color="black"
                          bg="primary"
                          variant="icon"
                          w={['2.5rem', '2.75rem']}
                          height={['2.5rem', '2.75rem']}
                        />
                        <Text mt={['4', '5']} size="lg" fontWeight="500">
                          {t(`home.assets.multimarket.features.${idx}.title`)}
                        </Text>
                        <Text mt={['1', '2']} color="white.cultered" size="sm">
                          {t(`home.assets.multimarket.features.${idx}.text`)}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                </Box>
                <Box
                  w="100%"
                  h="auto"
                  sx={{
                    '.gatsby-image-wrapper': {
                      height: 'auto',
                      width: '100%',
                      borderRadius: { base: '0', lg: '2rem' },
                    },
                  }}
                >
                  <GatsbyImage
                    image={getImage(images.allFile.nodes, 'multimarket')}
                    alt="multimarket image"
                  />
                </Box>
              </SimpleGrid>
            </Container>
          </TabPanel>
          <TabPanel>
            <Container p={{ base: 0, md: 0 }}>
              <SimpleGrid
                gridTemplateColumns={{
                  base: '1fr',
                  lg: 'calc(50% - 6rem) 1fr',
                }}
                spacing={['2.5rem', '6rem']}
                alignItems="center"
              >
                <Box textAlign="left" p={{ base: '0 1rem' }}>
                  <Heading mb="8" size="sm" as="h3">
                    {t('home.assets.multiassets.title')}
                  </Heading>
                  <List spacing={['4', '7']}>
                    {assets.assetsList.map((text, idx) => (
                      <ListItem display="flex" key={idx}>
                        <Icons name="check" />
                        <Text key={idx} marginLeft="3" size="lg">
                          {t(text)}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 4 }}
                    spacing="6"
                    mt={['3rem', '4rem']}
                  >
                    {assets.mulitassets.map((icon, idx) => (
                      <Box key={idx}>
                        <IconButton
                          as="span"
                          aria-label={icon}
                          icon={<Icons name={icon as IconsName} />}
                          color="black"
                          bg="primary"
                          variant="icon"
                          w={{ lg: '2.75rem' }}
                          h={{ lg: '2.75rem' }}
                        />
                        <Text marginTop="5" size="lg" fontWeight="500">
                          {t(`home.assets.multiassets.features.${idx}.title`)}
                        </Text>
                        <Text marginTop="2" color="white.cultered" size="sm">
                          {t(`home.assets.multiassets.features.${idx}.text`)}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                </Box>
                <Box
                  sx={{
                    '.gatsby-image-wrapper': {
                      height: 'auto',
                      width: '100%',
                      borderRadius: { base: '0', lg: '2rem' },
                    },
                  }}
                >
                  <GatsbyImage
                    image={getImage(images.allFile.nodes, 'multiassets')}
                    alt="image multiassets"
                  />
                </Box>
              </SimpleGrid>
            </Container>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Container>
        <Button
          variant="primary"
          size="lg"
          mt={['2rem', '6rem']}
          w={['100%', 'unset']}
          onClick={() =>
            scroller.scrollTo('main-form', { smooth: 'easeInOutQuint' })
          }
        >
          {t('home.assets.button')}
        </Button>
      </Container>
    </Box>
  )
}
export default HomeAssets
