import React, { FC } from 'react'
import { useStaticQuery, graphql, Node } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
import sanitizeHtml from 'sanitize-html'
import HomeData from 'content/home.json'
import Icons from 'components/Icons'
import { sectionPaddingY } from 'styles/global'
import { useSt } from 'state/global/context'
import {
  Box,
  Container,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

const HomeProducts: FC = () => {
  const { state, dispatch } = useSt()
  const { t, i18n } = useTranslation()
  const { products } = HomeData
  const cards = products.content[state.activeProductTab]
  const cardsList =
    i18n.language === 'fr' && cards.listFr ? cards.listFr : cards.list
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home/products" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: TRACED_SVG
              height: 640
            )
          }
          name
        }
      }
    }
  `)

  return (
    <Container id="home-products">
      <Box textAlign="center" p={sectionPaddingY}>
        <Heading maxW="1200px" margin="0 auto">
          {t('home.products.title')}
        </Heading>
        <Text
          maxW="1200px"
          margin="0 auto"
          size="xl"
          mt={['4', '5']}
          color="gray.400"
        >
          {t('home.products.subtitle')}
        </Text>
        <Tabs
          variant="row-list"
          colorScheme="black"
          mt="5"
          index={state.activeProductTab}
          onChange={idx => dispatch({ type: 'updateProductTab', index: idx })}
        >
          <TabList>
            {products.tabs.map((tab, idx) => (
              <Tab key={idx}>
                <Text size="xl" fontWeight="500" fontSize={['1rem', '1.25rem']}>
                  {tab}
                </Text>
                <Text color="gray.400" fontSize={['0.875rem', '1rem']}>
                  {t(`home.products.description.${idx}`)}
                </Text>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {products.images.map((img, idx) => {
              const image = images.allFile.nodes.find(
                (node: Node) => node.name === img
              )

              return (
                <TabPanel key={idx}>
                  <Box h="auto">
                    <GatsbyImage
                      alt={image.name}
                      image={image.childImageSharp.gatsbyImageData}
                      style={{
                        margin: '0 auto',
                        width: '100%',
                        height: ' auto',
                      }}
                      objectFit="contain"
                    />
                  </Box>
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
        <SimpleGrid
          mt="8"
          columns={{ base: 1, md: 2, xl: cardsList.length }}
          spacing={cardsList.length >= 4 ? '4' : '8'}
        >
          {cardsList.map((_card, cardIdx) => (
            <Box
              key={cardIdx}
              bg="blue.100"
              borderRadius="0.5rem"
              p={['2rem 1.5rem', '2rem 2rem 2.5rem']}
            >
              <Heading size="xs" mb="8" as="h3">
                {t(`${cards.translation}.${cardIdx}.title`)}
              </Heading>
              <List spacing="4">
                {[...Array(cardsList[cardIdx]).keys()].map(idx => {
                  return (
                    <ListItem display="flex" key={idx}>
                      <Box w="1.5rem" h="1.5rem">
                        <Icons name="check-black" />
                      </Box>
                      <Text
                        marginLeft="3"
                        textAlign="left"
                        sx={{ span: { color: 'gray.400' } }}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            t(`${cards.translation}.${cardIdx}.list.${idx}`, {
                              interpolation: { escapeValue: false },
                            })
                          ),
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          ))}
        </SimpleGrid>
        {/* <Text size="xl" color="gray.400" mt={['8', '16']}>
          {t(`home.products.inDevelopment`)}
        </Text> */}
      </Box>
    </Container>
  )
}
export default HomeProducts
