import React, { FC, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { sectionPaddingY } from 'styles/global'

import Layout from 'components/Layout'
import HomeHero from 'components/Home/HomeHero'
import HomeFeatures from 'components/Home/HomeFeatures'
import HomeAssets from 'components/Home/HomeAssets'
import HomeProducts from 'components/Home/HomeProducts'
import HomeBenefits from 'components/Home/HomeBenefits'
import FAQ from 'components/FAQ'
import MainForm from 'components/Form/MainForm'
import BlocEarlyAdopters from 'components/BlocEarlyAdopters'

import { Box, Container, Heading, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { scrollToSection } from 'helpers/scroll'
import { useSt } from 'state/global/context'

const IndexPage: FC<{ location: any }> = ({ location }) => {
  const { t, i18n } = useTranslation()
  const { state, dispatch } = useSt()

  useEffect(() => {
    if (location.state) {
      if (
        (location.state.scrollTo || location.state.action) &&
        state.homeHasLoaded
      ) {
        const section = {
          dispatch,
          action: location.state.action,
        }
        scrollToSection(
          location.state.scrollTo,
          location.state.action && section
        )
      }
    }
  }, [])

  return (
    <Layout>
      <HomeHero />
      <HomeFeatures />
      <HomeAssets />
      <HomeProducts />
      <HomeBenefits />
      <BlocEarlyAdopters
        button={
          <Button
            variant="black"
            mt={['10', '16']}
            size="lg"
            w={['full', 'unset']}
            onClick={() =>
              navigate(
                i18n.language === 'fr'
                  ? '/fr/early-adopters'
                  : '/early-adopters'
              )
            }
          >
            {t('earlyAdopters.bloc.button')}
          </Button>
        }
      />
      <Box
        textAlign="center"
        p={sectionPaddingY}
        bg="blue.100"
        color="black.600"
      >
        <Container>
          <Heading maxW="1200px" margin="0 auto" as="h3">
            {t('home.ctaAction.title')}
          </Heading>
          <Text maxW="1200px" m={['1rem auto 0', '1.25rem auto 0']} size="xl">
            {t('home.ctaAction.subtitle')}
          </Text>
        </Container>
      </Box>
      <FAQ
        title={t('home.faq.title')}
        subtitle={t('home.faq.subtitle')}
        data={t('home.faq.list', {
          returnObjects: true,
        })}
      />
      <MainForm
        title={t('home.form.title')}
        subtitle={t('home.form.subtitle')}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
